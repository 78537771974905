<template>
  <div>
    <j-file-manager 
      @download-clicked="setFileBrowserValues"
    />
  </div>
</template>

<script>
import __M from 'moment'

import { mapMutations, mapActions } from "vuex"
import __C from '@/primitives/_constant_'
import Loading from '@/mixins/loading.mixin'
import JFileManager from '@/components/JCommFileManager'

export default {
  name: 'service-file-manager',
  mixins: [
    Loading
  ],
  components: {
    JFileManager
  },
  data: () => ({
    catcode: ''
  }),
  computed: {
    routePath() { return this.$route.path }
  },
  watch: {
    routePath(val) {
      this.loading = false
      this.initFilebrowser()
      this.setFilebrowserChildId()
    }
  },
  created() {
    this.initFilebrowser()
    this.initService()
    this.setFilebrowserChildId()
    this.loading = false
  },
  methods: {
    ...mapMutations([ 
      'initFilebrowser' 
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setChild'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.APPLICATION, [
      'getFileBrowserValues', 
      'updateFileBrowserValues'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.DASHBOARD, [
      'initService'
    ]),

    setFilebrowserChildId() {
      if(this.$route.path == '/service/project/share_folder/public_data') var childId = __C.FILE_BROWSER.POUCH_DOCID_PUBLIC_DATA
      else if(this.$route.path == '/service/project/share_folder/jgs_data') childId = __C.FILE_BROWSER.POUCH_DOCID_JGS_DATA
      else if(this.$route.path == '/service/project/share_folder/jinsolution_user_manual') childId = __C.FILE_BROWSER.POUCH_DOCID_MANUAL_DATA
      else childId = __C.STORE_NAMESPACE_KEY.FILE_BROWSER

      this.catcode = childId
      this.setChild(childId)

      // init recentDownloads
      this.getFileBrowserValues(this.catcode)
    },
    setFileBrowserValues(values) {
      let filepath = (values.fbpath).split('/')
      let filename = decodeURI(filepath[filepath.length -1])

      this.getFileBrowserValues(this.catcode).then(res => {
        let recent__ = res.recent || []

        if(recent__.find(r => r.name == filename)) return
        if(recent__.length == 10) recent__.shift()

        recent__.push({ name: filename, fbname: values.fbname, fbpath: values.fbpath, date: __M().format('YYYY-MM-DD') })
        res.recent = recent__

        this.updateFileBrowserValues({
          id: this.catcode,
          values: res
        })
      })
    }
  }
}
</script>
